import Vue from 'vue'
import VueRouter from 'vue-router'
import i18n from '../i18n'
import VueSession from 'vue-session'
//Pages
/*
import Home from '../views/Home.vue'
import Dashboard from '../views/Dasboard.vue'
import Login from '../views/Login.vue'
import Register from '../views/Register.vue'
import LoginCode from '../views/Logincode.vue'
import Accactivate from '../views/Accountactivate.vue'
import FPass from '../views/PasswordForgot.vue'
import Newpass from '../views/Passwordnew.vue'
import Depositwithdraw from '../views/Depositwithdraw.vue'
import SwapHistory from '../views/SwapHistory.vue'
import Swap from '../views/Swap.vue'
import About from '../views/About.vue'
import Support from '../views/Support.vue'
import notFound from '../views/404.vue'

*/
const Home = () => import('../views/Home.vue');
const Dashboard = () => import('../views/Dasboard.vue');
const Login = () => import('../views/Login.vue');
const Register = () => import('../views/Register.vue');
const LoginCode = () => import('../views/Logincode.vue');
const Accactivate = () => import('../views/Accountactivate.vue');
const FPass = () => import('../views/PasswordForgot.vue');
const Newpass = () => import('../views/Passwordnew.vue');
const Depositwithdraw = () => import('../views/Depositwithdraw.vue');
const SwapHistory = () => import('../views/SwapHistory.vue');
const Swap = () => import('../views/Swap.vue');
const About = () => import('../views/About.vue');
const Support = () => import('../views/Support.vue');
const notFound = () => import('../views/404.vue');


Vue.use(VueRouter)
var options = {
  persist: true,
}
Vue.use(VueSession, options)

const routes = [
  {
    path: '/',
    redirect: `/${i18n.locale}`
  },
  {
    path: '/:lang',
    component: {
      render(c) { return c('router-view') }
    },
    children: [
      {
        path: "/",
        name: "home",
        component: Home
      },
      {
        path: "dashboard",
        name: "dashboard",
        component: Dashboard
      },
      {
        path: "deposit-withdraw-history",
        name: "deposit-withdraw",
        component: Depositwithdraw
      },
      {
        path: "swap-history",
        name: "swap-history",
        component: SwapHistory
      },
      {
        path: "swap",
        name: "Swap",
        component: Swap
      },
      {
        path: "login",
        name: "login",
        component: Login
      },
      {
        path: "register",
        name: "register",
        component: Register
      },
      {
        path: "logincode",
        name: "logincode",
        component: LoginCode
      },
      {
        path: "account/mailconfirm/:key*",
        name: "accountactivation",
        component: Accactivate
      },
      {
        path: "forgotpassword",
        name: "forgotpassword",
        component: FPass
      },
      {
        path: "account/newpassword/:key*",
        name: "newpassword",
        component: Newpass
      },
      {
        path: "about",
        name: "about",
        component: About
      },
      {
        path: "support",
        name: "support",
        component: Support
      },
      {
        path: "*",
        name: "404",
        component: notFound
      },
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: '',
  routes
})

export default router
