"use strict"
import Vue from 'vue'
import App from './App.vue'
import router from './router'
Vue.config.productionTip = false

import VueCryptojs from 'vue-cryptojs'
import i18n from './i18n'
import Vuelidate from "vuelidate"
import VueToastify from "vue-toastify";
import VueMeta from 'vue-meta'
import VueTour from 'vue-tour'

Vue.use(VueMeta)
Vue.use(VueCryptojs)
Vue.use(Vuelidate)
Vue.use(VueToastify)
Vue.use(VueTour)

require('vue-tour/dist/vue-tour.css')
require('@/assets/css/plugins.bundle.css');
require('@/assets/css/style.bundle.css')

router.beforeEach((to, from, next) => {
  let language = to.params.lang;
  if(!language){
    language = 'tr'
  }
  i18n.locale = language
  next()
})

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
